<template>
  <v-card>
    <v-card-title>
      <h2 style="font-size: 18px; width: 100%;" class="mb-0">{{ $t('label.vinculo_industria') }}</h2>
      <h4 class="RegisterForm__H4">{{cliente.idExterno}} - {{ cliente.nomCliente }}</h4>
    </v-card-title>

    <div v-show="!novo">
      <v-row justify="end">
        <div class="pt-3 mr-3">
          <v-tooltip bottom v-if="canAccessCRUD">
            <template v-slot:activator="{ on }">
              <v-btn id="btn_nova_carteira" icon class="mx-0" v-on="on" @click="novaCarteira()">
                <v-icon>add</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('label.novo') }}</span>
          </v-tooltip>
        </div>

        <v-col cols="12" sm="6" md="3" class="mr-2">
          <v-text-field
            id="campo_pesquisar"
            v-model="searchQuery"
            @input="filtrar"
            append-icon="search"
            :label="$t('label.pesquisar')"
            single-line
            clearable
            hide-details>
          </v-text-field>
        </v-col>
      </v-row>

      <v-data-table
        id="tabela_carteira"
        :headers="headersDinamico"
        :items="filtrar()"
        :no-data-text="$t('label.tabela_sem_conteudo')">
        <template v-slot:item="{ item }">
          <tr>
            <td>{{ item.nomeDivisao }}</td>
            <td v-for="(extensao, indice) in Object.keys(headersDinamicoObrigatorio)"
                v-bind:key="indice">
              {{ getNomesSeparadosPorVirgula(extensao, 'nomExtensao', item) }}
            </td>
            <td>
              <v-switch
                id="btn_ativo"
                color="primary"
                @change="alterarLiderConta(item)"
                v-model="item.indLiderConta"
                class="mt-3"
                :disabled="!canAccessCRUD || !item.indAtivo">
              </v-switch>
            </td>
            <td>
              <v-switch
                id="btn_ativo"
                color="primary"
                @change="ativarInativar(item)"
                v-model="item.indAtivo"
                class="mt-3"
                :disabled="!canAccessCRUD || item.indLiderConta">
              </v-switch>
            </td>
          </tr>
        </template>
      </v-data-table>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          id="btn_fechar"
          color="primary"
          text
          @click="fechar()"
        >
          {{ $tc('label.fechar', 1) }}
        </v-btn>
      </v-card-actions>
    </div>

    <div v-if="novo">
      <v-form ref="formDivisao" lazy-validation>
        <v-card elevation="0">
          <v-container grid-list-xl fluid>
            <metadados-container-layout
              :metadados-entidade="metadadosCarteiraCliente"
              :objeto="extensoesMetadados"
              :input-layout="inputLayout"
              :layout-class="layoutClass"
              v-if="metadadosCarteiraCliente"
              ref="container">
                <v-col
                  slot="antes"
                  cols="12" md="4">
                  <v-autocomplete
                    id="cliente_carteira_divisao"
                    name="cliente_carteira_divisao"
                    v-model="carteira.divisao"
                    :items="listaDivisoes"
                    :no-data-text="$tc('message.nenhum_registro', 1)"
                    item-text="nome"
                    item-value="id"
                    :label="`${$tc('label.divisao', 1)} *`"
                    :rules="[rules.required]"
                    clearable
                    @click:append="triggerCarteiraDivisao"
                    @click.native="buscarDivisaoAutocomplete"
                    required
                    return-object
                    @change="setDivisao"
                    :search-input.sync="searchInputDivisao"
                    >
                  </v-autocomplete>
                </v-col>
            </metadados-container-layout>
          </v-container>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn id="btn_cancelar_nova_carteira" @click="cancelarNovo()">{{ $t('label.cancelar') }}</v-btn>
            <v-btn id="btn_salvar_nova_carteira" @click="salvar()" color="primary">{{ $t('label.salvar') }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </div>
  </v-card>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import { getNomesSeparadosPorVirgula } from '../../common/functions/metadados';
import MetadadosContainerLayout from '../../shared-components/metadados/MetadadosContainerLayout';

export default {
  props: {
    carteiras: {
      type: Array,
      default: [],
    },
    cliente: {
      type: Object,
      default: {},
    },
    canAccessCRUD: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    MetadadosContainerLayout,
  },
  data() {
    return {
      clienteCarteiraImplicitaResources: this.$api.clienteCarteiraImplicita(this.$resource),
      divisaoResources: this.$api.divisao(this.$resource),
      extensaoResources: this.$api.extensao(this.$resource),
      carteira: {
        idExtensao: [],
        divisao: undefined,
      },
      extensoesMetadados: {},
      novo: false,
      searchQuery: '',
      listaDivisoes: [],
      listaUnidades: [],
      rules: {
        required: (value) => !!value || this.$t('message.campo_obrigatorio'),
      },
      headers: [
        { text: this.$tc('label.divisao', 1), value: 'divisao', sortable: false },
        { text: this.$tc('label.lider_conta', 1), value: 'lider_conta', sortable: false },
        { text: this.$tc('label.ativo', 1), value: 'ativo', sortable: false },
      ],
      searchInputDivisao: null,
      resourceMetadado: this.$api.metadado(this.$resource),
      parametrizacaoClienteResources: this.$api.parametrizacaoCliente(this.$resource),
      listaConfiguracoes: [],
      layoutClass: { wrap: true },
      inputLayout: {
        xs12: true,
        sm12: false,
        md12: false,
        sm6: true,
        md3: true,
        md4: false,
        md6: false,
        md9: false,
      },
    };
  },
  watch: {
    searchInputDivisao(val) {
      if (this.carteira.divisao && this.carteira.divisao.nome === val) {
        return;
      }

      if (this.timeout) {
        window.clearTimeout(this.timeout);
      }
      this.timeout = window.setTimeout(() => {
        if (val != null) this.buscarDivisaoAutocomplete(val);
      }, 500);
    },
  },
  methods: {
    getNomesSeparadosPorVirgula,
    ...mapActions('metadados', [
      'setCarteiraClienteMetadado',
    ]),
    buscarConfig() {
      const param = {
        chave: 'VINCULO_CARTEIRA_CLIENTE',
      };

      this.parametrizacaoClienteResources.buscarTodos(param, param)
        .then((response) => {
          if (response.data) {
            this.listaConfiguracoes = response.data.map((c) => c.valor);
          }
        }).catch((err) => {
          this.$error(this, err);
        });
    },
    fechar() {
      if (this.carteiras.length && !this.possuiLiderConta()) {
        this.$toast(this.$t('message.lider_conta_obrigatorio'));
        return;
      }
      this.novo = false;
      this.searchQuery = '';
      this.$emit('fechar');
    },
    buscarDivisaoAutocomplete(content) {
      if (typeof content !== 'string') {
        content = null;
      }

      this.divisaoResources.selecao({ autocomplete: content }).then((response) => {
        this.listaDivisoes = Object.assign([], response.data);
      }, (err) => {
        this.$error(this, err);
      });
    },
    setDivisao() {
      this.$forceUpdate();
    },
    buscarUnidadeAutocomplete() {
      const param = {
        idNivelExtensao: 1,
        idDivisao: this.carteira.divisao.id,
        idCliente: this.cliente.id,
        size: 20,
        page: 0,
      };

      this.extensaoResources.listarAutocomplete(param).then((response) => {
        this.listaUnidades = Object.assign([], response.data.resposta);
      }, (err) => {
        this.$error(this, err);
      });
    },
    novaCarteira() {
      this.carteira = {};
      this.carteira.idVinculo = null;
      this.carteira.idExtensao = [];
      this.novo = true;
    },
    cancelarNovo() {
      this.extensoesMetadados = {};
      this.novo = false;
    },
    salvar() {
      if (!this.$refs.formDivisao.validate()) return;

      if (!this.carteira.divisao.id) return;

      if (!this.idsExtensoesMetadadosFiltrados.length) return;

      const idCliente = this.cliente.id;
      this.carteira.idVinculo = this.carteira.divisao.id;
      this.carteira.idExtensao = this.idsExtensoesMetadadosFiltrados;
      if (!this.possuiLiderConta()) {
        this.carteira.indLiderConta = true;
      } else {
        this.carteira.indLiderConta = false;
      }

      this.clienteCarteiraImplicitaResources.inserir({ idCliente }, this.carteira)
        .then(() => {
          this.novo = false;
          this.extensoesMetadados = {};
          this.$emit('carteira_adicionada');
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    ativarInativar(item) {
      const idCliente = this.cliente.id;

      const obj = {};
      obj.id = item.id;
      obj.indAtivo = item.indAtivo;

      this.clienteCarteiraImplicitaResources.ativarInativar({ idCliente }, obj)
        .then(() => {
          this.$toast(this.$t('message.atualizado_com_sucesso'));
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    alterarLiderConta(item) {
      const idCliente = this.cliente.id;

      const obj = {};
      obj.id = item.id;
      obj.indLiderConta = item.indLiderConta;

      this.clienteCarteiraImplicitaResources.alterarLiderConta({ idCliente }, obj)
        .then(() => {
          this.$toast(this.$t('message.atualizado_com_sucesso'));
          this.$emit('carteira_alterada');
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    filtrar() {
      if (this.searchQuery && this.searchQuery !== '') {
        const filtered = this.carteiras.filter((el) => {
          const auxa = Object.keys(el).map((key) => {
            const entry = el[key];
            return (typeof entry === 'object')
              ? JSON.stringify(entry).toLowerCase()
              : entry.toString().toLowerCase();
          });
          const result = auxa.filter(elem => ~elem.indexOf(this.searchQuery.toLowerCase())); // eslint-disable-line
          return result.length > 0;
        });
        return filtered;
      }
      return this.carteiras;
    },
    possuiLiderConta() {
      let possuiLiderConta = false;
      this.carteiras.forEach((item) => {
        if (item.indLiderConta) {
          possuiLiderConta = true;
        }
      });
      return possuiLiderConta;
    },
    triggerCarteiraDivisao() {
      document.getElementById('cliente_carteira_divisao').click();
    },
    triggerCarteiraUnidade() {
      document.getElementById('cliente_carteira_unidade').click();
    },
  },
  computed: {
    ...mapGetters('metadados', [
      'getCarteiraClienteMetadado',
    ]),
    idsExtensoesMetadadosFiltrados() {
      const mapaExtensoes = { ...this.$refs.container.getValoresDependencias() };
      if (!this.extensoesMetadados || !mapaExtensoes) {
        return [];
      }
      const idsExtensoes = [];
      Object.keys(this.extensoesMetadados)
        .forEach((el) => {
          const item = mapaExtensoes[`id_${el}`];
          if (typeof (item) === 'number') {
            idsExtensoes.push(item);
          } else {
            idsExtensoes.push(...item);
          }
        });
      return idsExtensoes;
    },
    metadadosCarteiraCliente() {
      if (this.getCarteiraClienteMetadado && this.getCarteiraClienteMetadado.mapaEntidades) {
        return this.getCarteiraClienteMetadado;
      }
      return undefined;
    },
    headersDinamico() {
      const headers = [];
      if (!this.metadadosCarteiraCliente) return this.headers;

      headers.push(this.headers[0]);

      Object.keys(this.metadadosCarteiraCliente.mapaEntidades)
        .forEach((entidade) => {
          const mapeamentoEntidade = this.metadadosCarteiraCliente.mapaEntidades[entidade];
          if (this.listaConfiguracoes.includes(mapeamentoEntidade.mnemonico)) {
            headers.push({
              text: mapeamentoEntidade.entidadeEstrangeira,
              value: mapeamentoEntidade.label,
              sortable: false,
              class: 'ClienteListDados_headers_min_width',
            });
          }
        });

      headers.push(...this.headers.slice(1, 3));

      return headers;
    },
    headersDinamicoObrigatorio() {
      const headers = {};
      Object.keys(this.metadadosCarteiraCliente.mapaEntidades)
        .forEach((entidade) => {
          const mapeamentoEntidade = this.metadadosCarteiraCliente.mapaEntidades[entidade];
          if (this.listaConfiguracoes.includes(mapeamentoEntidade.mnemonico)) {
            headers[entidade] = mapeamentoEntidade;
          }
        });
      return headers;
    },
  },
  beforeMount() {
    this.buscarConfig();
  },
};
</script>
