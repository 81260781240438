<template>
  <v-card>
    <v-card-title>
      <h2 style="font-size: 18px; width: 100%;" class="mb-0">{{ $t('label.vinculo_industria') }}</h2>
      <h4 class="RegisterForm__H4">{{cliente.idExterno}} - {{ cliente.nomCliente }}</h4>
    </v-card-title>

    <div v-show="!novo">
      <v-row justify="end">
        <div class="pt-3 mr-3">
          <v-tooltip bottom v-if="canAccessCRUD">
            <template v-slot:activator="{ on }">
              <v-btn id="btn_nova_carteira" icon class="mx-0" v-on="on" @click="novaCarteira()">
                <v-icon>add</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('label.novo') }}</span>
          </v-tooltip>
        </div>

        <v-col cols="12" sm="6" md="3" class="mr-2">
          <v-text-field
            id="campo_pesquisar"
            v-model="searchQuery"
            @input="filtrar"
            append-icon="search"
            :label="$t('label.pesquisar')"
            single-line
            clearable
            hide-details>
          </v-text-field>
        </v-col>
      </v-row>

      <v-data-table
        id="tabela_carteiras"
        :headers="headers"
        :items="filtrar()"
        :no-data-text="$t('label.tabela_sem_conteudo')"
        hide-default-footer
      >
        <template v-slot:item.unidade_negocio="{ item }">
          {{ item.nomExtensao }}
        </template>
        <template v-slot:item.ativo="{ item }">
          <v-switch
              id="btn_ativo"
              color="primary"
              @change="ativarInativar(item)"
              v-model="item.indAtivo"
              class="mt-3"
              :disabled="!canAccessCRUD">
            </v-switch>
        </template>
      </v-data-table>
    </div>

    <div v-show="novo">
      <v-form ref="formFuncionario" lazy-validation>
        <v-card elevation="0">
          <v-container grid-list-xl fluid>
            <v-row>
              <v-col cols="12" md="6">
                <v-autocomplete
                  id="cliente_carteira_nome"
                  name="cliente_carteira_nome"
                  v-model="carteira.funcionario"
                  :items="listaFuncionarios"
                  :no-data-text="$tc('message.nenhum_registro', 1)"
                  item-text="nome"
                  item-value="idFuncionario"
                  :label="`${$tc('label.nome', 1)} *`"
                  :rules="[rules.required]"
                  @click:append="triggerCarteiraFuncionario"
                  @click.native="buscarFuncionarioAutocomplete"
                  required
                  @change="setFuncionario"
                  return-object
                  :search-input.sync="searchInputFuncionario"
                  >
                </v-autocomplete>
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field
                  id="campo_codigo"
                  name="campo_codigo"
                  v-model="carteira.funcionario.matricula"
                  :label="`${$tc('label.matricula', 1)}`"
                  :disabled="true"
                >
                </v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field
                  id="campo_email"
                  name="campo_email"
                  v-model="carteira.funcionario.email"
                  :label="`${$tc('label.email', 1)}`"
                  :disabled="true"
                >
                </v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                <v-autocomplete
                  id="cliente_carteira_unidade"
                  name="cliente_carteira_unidade"
                  v-model="carteira.idExtensao"
                  :items="listaUnidades"
                  :no-data-text="$tc('message.nenhum_registro', 1)"
                  item-text="nomExtensao"
                  item-value="id"
                  :label="`${$tc('label.unidade_negocio', 1)} *`"
                  :rules="[rules.required]"
                  required
                  clearable
                  @click:append="triggerCarteiraUnidade"
                  @click.native="buscarUnidadeAutocomplete"
                  :disabled="!carteira.funcionario.idFuncionario"
                  :chips="true"
                  multiple>
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-container>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn id="btn_cancelar_nova_carteira" @click="cancelarNovo()">{{ $t('label.cancelar') }}</v-btn>
            <v-btn id="btn_salvar_nova_carteira" @click="salvar()" color="primary">{{ $t('label.salvar') }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </div>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        id="btn_fechar"
        color="primary"
        text
        @click="fechar()"
      >
        {{ $tc('label.fechar', 1) }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
export default {
  props: {
    carteiras: {
      type: Array,
      default: [],
    },
    cliente: {
      type: Object,
      default: {},
    },
    canAccessCRUD: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      clienteCarteiraImplicitaResources: this.$api.clienteCarteiraImplicita(this.$resource),
      extensaoResources: this.$api.extensao(this.$resource),
      funcionarioResources: this.$api.funcionario(this.$resource),
      carteira: {
        funcionario: {},
        idExtensao: [],
      },
      headers: [
        { text: this.$tc('label.matricula', 1), value: 'matricula', sortable: false },
        { text: this.$tc('label.nome', 1), value: 'nome', sortable: false },
        { text: this.$tc('label.email', 1), value: 'email', sortable: false },
        { text: this.$tc('label.unidade_negocio', 1), value: 'unidade_negocio', sortable: false },
        { text: this.$tc('label.ativo', 1), value: 'ativo', sortable: false },
      ],
      novo: false,
      searchQuery: '',
      searchInputFuncionario: null,
      listaFuncionarios: [],
      listaUnidades: [],
      rules: {
        required: (value) => !!value || this.$t('message.campo_obrigatorio'),
      },
    };
  },
  watch: {
    searchInputFuncionario(val) {
      if (this.carteira.funcionario && this.carteira.funcionario.nome === val) {
        return;
      }

      if (this.timeout) {
        window.clearTimeout(this.timeout);
      }
      this.timeout = window.setTimeout(() => {
        if (val != null) this.buscarFuncionarioAutocomplete(val);
      }, 500);
    },
  },
  methods: {
    fechar() {
      this.novo = false;
      this.searchQuery = '';
      this.$emit('fechar');
    },
    buscarFuncionarioAutocomplete(content) {
      if (typeof content !== 'string') {
        content = null;
      }
      this.funcionarioResources.selecao({ autocomplete: content }).then((response) => {
        this.listaFuncionarios = Object.assign([], response.data);
      }, (err) => {
        this.$error(this, err);
      });
    },
    setFuncionario() {
      this.$forceUpdate();
    },
    buscarUnidadeAutocomplete() {
      const param = {
        idNivelExtensao: 1,
        idUsuario: this.carteira.funcionario.idFuncionario,
        idCliente: this.cliente.id,
        size: 20,
        page: 0,
      };

      this.extensaoResources.listarAutocomplete(param).then((response) => {
        this.listaUnidades = Object.assign([], response.data.resposta);
      }, (err) => {
        this.$error(this, err);
      });
    },
    novaCarteira() {
      this.carteira = {};
      this.carteira.funcionario = {};
      this.carteira.idExtensao = [];
      this.novo = true;
    },
    cancelarNovo() {
      this.novo = false;
    },
    salvar() {
      if (!this.$refs.formFuncionario.validate()) return;

      if (!this.carteira.funcionario.idFuncionario) return;

      if (!this.carteira.idExtensao.length) return;

      const idCliente = this.cliente.id;
      this.carteira.idVinculo = this.carteira.funcionario.idFuncionario;

      this.clienteCarteiraImplicitaResources.inserir({ idCliente }, this.carteira)
        .then(() => {
          this.novo = false;
          this.$emit('carteira_adicionada');
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    ativarInativar(item) {
      const idCliente = this.cliente.id;

      const obj = {};
      obj.id = item.id;
      obj.indAtivo = item.indAtivo;

      this.clienteCarteiraImplicitaResources.ativarInativar({ idCliente }, obj)
        .then(() => {
          this.$toast(this.$t('message.atualizado_com_sucesso'));
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    filtrar() {
      if (this.searchQuery && this.searchQuery !== '') {
        const filtered = this.carteiras.filter((el) => {
          const auxa = Object.keys(el).map((key) => {
            const entry = el[key];
            return (typeof entry === 'object')
              ? JSON.stringify(entry).toLowerCase()
              : entry.toString().toLowerCase();
          });
          const result = auxa.filter(elem => ~elem.indexOf(this.searchQuery.toLowerCase())); // eslint-disable-line
          return result.length > 0;
        });
        return filtered;
      }
      return this.carteiras;
    },
    triggerCarteiraFuncionario() {
      document.getElementById('cliente_carteira_nome').click();
    },
    triggerCarteiraUnidade() {
      document.getElementById('cliente_carteira_unidade').click();
    },
  },
};
</script>
