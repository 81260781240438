<template>
  <v-card>
    <v-card-title>
      <h2 style="font-size: 18px; width: 100%;" class="mb-0">{{ $t('label.contato') }}</h2>
      <h4 class="RegisterForm__H4">{{cliente.idExterno}} - {{ cliente.nomContato }}</h4>
    </v-card-title>

    <div>
      <v-data-table
        id="tabela_contato"
        :headers="headers"
        :items="getClienteLista()"
        :no-data-text="$t('label.tabela_sem_conteudo')"
        hide-default-footer
      >
        <template v-slot:item.nome="{ item }">
          {{ item.nomCliente }}
        </template>
        <template v-slot:item.email="{ item }">
          {{ item.vlrEmail }}
        </template>
        <template v-slot:item.telefone="{ item }">
          {{ item.vlrTelefone }}
        </template>
      </v-data-table>
    </div>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        id="btn_fechar_contato"
        color="primary"
        text
        @click="fechar()"
      >
        {{ $tc('label.fechar', 1) }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
export default {
  props: {
    cliente: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      headers: [
        { text: this.$tc('label.nome', 1), value: 'nome', sortable: false },
        { text: this.$tc('label.email', 1), value: 'email', sortable: false },
        { text: this.$tc('label.telefone', 1), value: 'telefone', sortable: false },
      ],
    };
  },
  methods: {
    fechar() {
      this.$emit('fechar');
    },
    getClienteLista() {
      const lista = [];
      lista.push(this.cliente);
      return lista;
    },
  },
};
</script>
